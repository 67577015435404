@import "scss/variables";
@import "scss/mixins";
@import "scss/animations";

.layout__theme-switch {
  position: fixed;
  top: -5px;
  right: 60px;
  z-index: 99999;

  @media (min-width: $media-breakpoint-up-md) {
    top: -2px;
  }

  @media (min-width: $media-breakpoint-up-lg) {
    top: 1px;
    right: 15px;
  }

  @media (min-width: $media-breakpoint-up-xl) {
    top: 4px;
  }

  input[type="checkbox"] {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 40px;
    height: 25px;
    background: var(--primary-bg-dark);
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
    position: relative;
    @include boxShadowLg();
  }

  label:after {
    content: "";
    background-image: url("../../../../assets/img/png/sun.png");
    background-size: cover;
    width: 20px;
    height: 20px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    position: absolute;
    top: 3px;
    left: 4px;
    transition: cubic-bezier(0.68, -0.55, 0.27, 01.55) 320ms;
  }

  input:checked+label {
    background: var(--primary-bg);
  }

  input:checked+label:after {
    left: calc(100% - 5px);
    background-image: url("../../../../assets/img/png/moon.png");
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}