:root[data-theme="light"] {
  --tag-style-bg: rgba(83, 109, 130, 0.562);
  --tag-style-border: rgb(83, 109, 130);
}
:root[data-theme="dark"] {
  --tag-style-bg: #273b56;
  --tag-style-border: #192739;
}
@mixin tagsStyle() {
  $default-color: #fff;
  $default-bg-color: var(--tag-style-bg);
  $default-border-color: 3px solid var(--tag-style-border);
  font-weight: bold;
  $text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.623);
  > span {
    text-shadow: $text-shadow;
  }
  .Express,
  .POO,
  .MVC,
  .SPA,
  .JWT,
  .Helmet,
  .WebSockets,
  .Socketio,
  .MomentJS,
  .Vagrant,
  .Xamp,
  .Brackets,
  .JSON,
  .Axios,
  .SEO,
  .SEM,
  .Auth0 {
    > span {
      color: $default-color;
      background-color: $default-bg-color;
      border: $default-border-color;
    }
  }
  .Bootstrap {
    > span {
      color: #fff;
      background-color: rgba(93, 42, 117, 0.479);
      border: 3px solid rgb(93, 42, 117);
    }
  }
  .GitHub {
    > span {
      color: #fff;
      background-color: rgba(0, 0, 0, 0.473);
      border: 3px solid #000;
    }
  }
  .GIT {
    > span {
      color: rgb(63, 52, 46);
      background-color: #fff;
      border: 3px solid rgb(247, 21, 49);
    }
  }
  .VisualStudioCode {
    > span {
      color: $default-color;
      background-color: rgba(0, 109, 199, 0.493);
      border: 3px solid rgb(0, 111, 199);
    }
  }
  .Laravel {
    > span {
      color: #fff;
      background-color: rgba(253, 44, 40, 0.555);
      border: 3px solid rgb(253, 42, 40);
    }
  }
  .PHP {
    > span {
      color: #fff;
      background-color: rgba(115, 120, 171, 0.671);
      border: 3px solid rgb(115, 120, 171);
    }
  }
  .Symfony {
    > span {
      color: #fff;
      background-color: rgba(0, 0, 0, 0.603);
      border: 3px solid rgb(0, 0, 0);
    }
  }
  .Postman {
    > span {
      color: #fff;
      background-color: rgba(254, 108, 63, 0.589);
      border: 3px solid rgb(254, 106, 63);
    }
  }
  .Robo3T {
    > span {
      color: #fff;
      background-color: rgba(71, 168, 76, 0.589);
      border: 3px solid rgb(71, 168, 76);
    }
  }
  .JavaScript {
    > span {
      color: #000;
      background-color: rgba(236, 225, 74, 0.541);
      border: 3px solid rgb(236, 225, 74);
    }
  }
  .TypeScript {
    > span {
      color: #fff;
      background-color: rgba(0, 110, 194, 0.59);
      border: 3px solid rgb(0, 109, 194);
    }
  }
  .JQuery {
    > span {
      color: #fff;
      background-color: rgba(12, 100, 167, 0.5);
      border: 3px solid rgb(12, 101, 167);
    }
  }
  .Angular {
    > span {
      color: #fff;
      background-color: rgba(241, 0, 56, 0.521);
      border: 3px solid rgb(241, 0, 55);
    }
  }
  .React,
  .ReactJS,
  .JSX {
    > span {
      color: #fff;
      background-color: rgba(41, 213, 240, 0.404);
      border: 3px solid rgb(41, 213, 240);
    }
  }
  .Node,
  .NodeJS {
    > span {
      color: #fff;
      background-color: rgba(0, 120, 26, 0.514);
      border: 3px solid rgb(0, 120, 26);
    }
  }
  .MongoDB {
    > span {
      color: #fff;
      background-color: rgba(0, 166, 77, 0.5);
      border: 3px solid rgb(0, 166, 77);
    }
  }
  .Mongoose {
    > span {
      color: #fff;
      background-color: rgba(148, 0, 10, 0.493);
      border: 3px solid rgb(148, 0, 11);
    }
  }
  .HTML5 {
    > span {
      color: #fff;
      background-color: rgba(255, 81, 54, 0.493);
      border: 3px solid rgb(255, 81, 54);
    }
  }
  .CSS3 {
    > span {
      color: #fff;
      background-color: rgba(70, 61, 234, 0.493);
      border: 3px solid rgb(71, 61, 234);
    }
  }
  .AntDesign {
    > span {
      color: #fff;
      background-color: rgba(52, 96, 240, 0.5);
      border: 3px solid rgb(52, 97, 240);
    }
  }
  .MySQL {
    > span {
      color: #fff;
      background-color: rgba(0, 94, 135, 0.486);
      border: 3px solid rgb(0, 94, 135);
    }
  }
  .MariaDB {
    > span {
      color: #fff;
      background-color: rgba(195, 150, 110, 0.514);
      border: 3px solid rgb(195, 149, 110);
    }
  }
  .Vue,
  .VueJS {
    > span {
      color: rgb(48, 70, 89);
      background-color: rgba(0, 189, 132, 0.507);
      border: 3px solid rgb(0, 189, 131);
    }
  }
  .SASS {
    > span {
      color: #fff;
      background-color: rgba(215, 75, 145, 0.5);
      border: 3px solid rgb(215, 75, 146);
    }
  }
  .AJAX,
  .Ajax {
    > span {
      color: #fff;
      background-color: rgba(20, 126, 192, 0.494);
      border: 3px solid rgb(20, 126, 192);
    }
  }
  .GoogleAnalytics {
    > span {
      color: #fff;
      background-color: rgb(255, 172, 56);
      border: 3px solid rgb(241, 107, 40);
    }
  }
  .EmailJS {
    > span {
      color: #fff;
      background-color: rgba(255, 160, 96, 0.664);
      border: 3px solid rgb(255, 159, 96);
    }
  }
  .Jest {
    > span {
      color: #fff;
      background-color: rgba(192, 64, 16, 0.6);
      border: 3px solid rgb(192, 64, 16);
    }
  }
  .Firebase {
    > span {
      color: #fff;
      background-color: rgba(237, 122, 13, 0.6);
      border: 3px solid rgb(237, 122, 13);
    }
  }
  .Docker {
    > span {
      color: #fff;
      background-color: rgba(3, 150, 192, 0.3);
      border: 3px solid rgb(3, 150, 192);
    }
  }
  .Wordpress {
    > span {
      color: #fff;
      background-color: rgba(32, 113, 150, 0.3);
      border: 3px solid rgb(32, 113, 150);
    }
  }
  .Prestashop {
    > span {
      color: #fff;
      background-color: rgba(223, 81, 131, 0.3);
      border: 3px solid rgb(223, 81, 131);
    }
  }
  .Bigcommerce {
    > span {
      color: #fff;
      background-color: rgba(52, 49, 63, 0.3);
      border: 3px solid rgb(52, 49, 63);
    }
  }
  .Shopify {
    > span {
      color: #fff;
      background-color: rgba(138, 192, 78, 0.3);
      border: 3px solid rgb(138, 192, 78);
    }
  }
}
