@import "scss/index.scss";

.project-layout {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 40px;
  padding: 20px 10px;

  @media (min-width: $media-breakpoint-up-md) {
    margin-bottom: 0px;
  }

  &__use-near-screen {
    min-height: 350px;
  }

  .clearfix {
    overflow: auto;
  }

  .clearfix::after {
    content: "";
    clear: both;
    display: table;
  }

  &-left {
    float: left;
  }

  &-right {
    float: right;
  }

  &__image {
    max-width: 400px;
    margin-bottom: 10px;

    @media (min-width: $media-breakpoint-up-md) {
      padding: 10px;
      border-right: 0;
      border-radius: 8px;
      margin-bottom: 0;
    }

    img {
      border-radius: 5px;
      @include boxShadowMd();
    }
  }

  &__content {
    display: grid;
    padding: 5px 0;
    border-radius: 0px;

    @media (min-width: $media-breakpoint-up-md) {
      padding: 0 15px;
      border-left: 0;
    }

    &-title {
      display: flex;
      align-items: center;
      justify-content: center;

      h2 {
        margin: 0;
        padding: 0;
        @include titleH2();
        color: var(--t-primary-color-dark);
        margin-bottom: 10px;

        a {
          color: $primary-color;
          padding: 0 10px;

          &:hover,
          &:active,
          &:focus {
            color: $font-primary-dark;
          }
        }
      }
    }

    &-text {
      text-align: justify;
      display: flex;
      align-items: center;
      max-width: 600px;
      margin: 0 auto;

      p {
        @include p();
        padding: 0 10px;
        margin-bottom: 0;
        margin-bottom: 10px;
        text-indent: 15px;
        color: var(--t-primary-color);

        @media (min-width: $media-breakpoint-up-md) {
          margin-top: 10px;
          padding: 0;
        }
      }
    }

    .ant-btn {
      width: 200px;
      margin: 0 auto;
      margin-bottom: 20px;
      margin-top: 10px;
      @include fadeIn();
      animation-duration: 1s;
      @include buttonPrimary();
    }

    &--links {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      a {
        color: var(--t-primary-color);
        font-size: 0.9rem;
        padding-bottom: 10px;

        &:hover {
          color: var(--t-primary-color-dark);
        }
      }
    }
  }

  &__modal {
    &-image {
      @include boxShadowLg();
    }

    &-content {
      background: var(--t-modal-bg);

      @media (min-width: $media-breakpoint-up-lg) {
        padding: 10px 20px;
      }

      &-date {
        text-align: center;
        margin: 15px 0;
        margin-top: 20px;
        @include titleH2();
        color: var(--t-primary-color-dark);
      }

      &-large-text,
      &-introduction {
        padding: 0px 20px;
        padding-bottom: 0;
        font-weight: 500;
        letter-spacing: 0.06em;
        text-indent: 15px;
        @include p();
        color: var(--t-primary-color);
      }
    }

    &-tags {
      text-align: center;
      background: var(--t-modal-bg);
      padding: 0 10px;
      padding-bottom: 20px;
      padding-top: 10px;
      border-bottom-left-radius: 7px;
      border-bottom-right-radius: 7px;

      >span {
        >span {
          @include boxShadowLg();
          margin: 8px 5px;
          border: 0;
          border-radius: 10px;
          padding: 4px;
          font-size: 1em;

          @media (min-width: $media-breakpoint-up-sm) {
            font-size: 1.1em;
            padding: 5px;
          }

          @media (min-width: $media-breakpoint-up-md) {
            margin: 8px;
          }
        }
      }

      @include tagsStyle();
    }
  }
}

.project-layout-modal-ant {
  margin-top: 60px;

  >div {
    border-radius: 7px !important;
    background: var(--t-modal-bg);
  }

  width: 100% !important;

  @media (min-width: $media-breakpoint-up-sm) {
    width: 90% !important;
  }

  @media (min-width: $media-breakpoint-up-md) {
    width: 80% !important;
    margin-top: 70px;
  }

  @media (min-width: $media-breakpoint-up-lg) {
    margin-top: 80px;
  }

  @media (min-width: $media-breakpoint-up-xl) {
    margin-top: 90px;
  }

  .ant-modal {
    &-header {
      border-top-left-radius: 10px !important;
      border-top-right-radius: 10px !important;
      background: var(--t-modal-bg);
      border: 0;
    }

    &-body {
      text-align: justify;
      padding: 0px;
    }

    &-close-icon {
      color: var(--t-primary-color);
      position: absolute;
      top: 10px;
      right: 10px;
    }

    &-title {
      padding: 5px 10px;
      text-transform: uppercase;
      text-align: center;
      @include titleH2();
      color: var(--t-primary-color-dark);

      @media (min-width: $media-breakpoint-up-sm) {
        padding: 10px 10px;
      }

      @media (min-width: $media-breakpoint-up-md) {
        padding: 15px 10px;
      }
    }

  }
}