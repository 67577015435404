@import "scss/variables";
@import "scss/mixins";

.layout-admin {
  transition: margin-left 0.2s;
  &__header {
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100%;
    left: 0;
    height: 65px;
    padding: 0;
    @include boxShadowXl();
  }
  &__content {
    min-height: calc(100vh - 62px);
    padding: 90px 25px 25px 25px;
  }
  &__footer {
    width: 100%;
    padding: 20px;
  }
}

.ant-notification {
  height: 40px !important;
  margin: 0 auto !important;
  padding: 0 !important;
  &-notice {
    height: 40px !important;
    text-align: left;
    margin: 0;
    padding: 0;
    padding-top: 7px;
    padding-left: 7px;
    background-color: $primary-color-dark;
    border: 2px solid $primary-color-extra-dark;
    border-radius: 5px;
    @include boxShadowLg();
    &-closable {
      height: 40px !important;
    }
    &-message {
      color: #fff !important;
      font-size: 1em !important;
      padding-top: 2px;
    }
  }
  &-close-x {
    color: $secondary-color !important;
    position: absolute !important;
    top: -7px !important;
    right: -15px;
  }
}
