@import "scss/index.scss";

.education {
  display: flex;
  justify-content: center;
  &__info-courses {
    min-height: 100vh;
  }
  .div {
    margin-top: 30px;
  }
  &__button {
    display: flex;
    justify-content: center;
    &-left {
      @include buttonPrimaryLight();
      margin: 0 5px;
    }
    &-right {
      @include buttonPrimary();
      margin: 0 5px;
    }
  }
}
