$font-size-xs: 15px;
$font-size-sm: 16px;
$font-size-md: 17px;
$font-size-lg: 18px;
$font-size-xl: 20px;

$primary-color-extra-dark: #192739;
$primary-color-dark: #273b56;
$primary-color: #5d718d;
$primary-color-medium: #7b93b6;
$primary-color-light: #cdd5df;
$primary-color-hover: #326290;

$secondary-color: rgb(255, 199, 62);
$secondary-color-transparent: rgba(255, 200, 62, 0.8);
$secondary-color-transparent-light: rgba(255, 200, 62, 0.6);

$font-light: #fff;
$font-dark-light: #4d4d53;
$font-primary: #384f6e;
$font-primary-light: #5d718d;
$font-primary-dark: #273b56;
$font-primary-extra-dark: #192739;

$border-grey: #808080;
$border-radius: 3px;

$background-grey-light: #e8e8e8;
$background-success: #84b84c1f;
$background-error: #ff00001f;

$success: #84b84c;
$error: #f00;

// Social color
$github: #333;
$linkedin: #0077b5;

// Responsesive down size
$media-breakpoint-down-xs: 413.98px;
$media-breakpoint-down-sm: 573.98px;
$media-breakpoint-down-md: 767.98px;
$media-breakpoint-down-lg: 991.98px;
$media-breakpoint-down-xl: 1199.98px;
$media-breakpoint-down-xxl: 1699.98px;

// Responsive up size
$media-breakpoint-up-xs: 320px;
$media-breakpoint-up-sm: 576px;
$media-breakpoint-up-md: 768px;
$media-breakpoint-up-lg: 992px;
$media-breakpoint-up-xl: 1200px;
$media-breakpoint-up-xxl: 1600px;
