@import "scss/variables";

.main-title {
  .combined {
    display: flex;
    justify-content: center;
    max-width: 355px;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;

    &-wrapper {
      padding: 0;
      height: 250px !important;
      position: relative;

      @media (max-width: $media-breakpoint-down-sm) {
        height: 230px !important;
      }
    }

    &-bar {
      background: var(--t-primary-color-dark);
      position: absolute;
      width: 100% !important;
      height: 3px;
      top: 143px;

      @media (max-width: $media-breakpoint-down-sm) {
        top: 141px;
      }
    }

    .title {
      color: var(--t-primary-color-dark);
      display: flex;
      justify-content: center;
      font-size: 40px;
      padding-top: 30px;
      position: absolute;
      width: 150px;

      @media (max-width: $media-breakpoint-down-sm) {
        font-size: 35px;
        padding-top: 45px;
      }
    }

    .title-2 {
      color: var(--t-primary-color-dark);
      display: flex;
      justify-content: center;
      font-size: 40px;
      margin: 0;
      padding-top: 75px;
      position: absolute;
      width: 130px;

      @media (max-width: $media-breakpoint-down-sm) {
        font-size: 35px;
        padding-top: 85px;
      }
    }

    .content {
      color: var(--t-primary-color);
      font-size: 30px;
      text-align: center;
      position: absolute;
      top: 160px;

      @media (max-width: $media-breakpoint-down-sm) {
        font-size: 28px;
        top: 155px;
      }
    }
  }
}