@import "scss/variables";
@import "scss/mixins";

:root[data-theme='light'] {
  --t-social-links-border: 1px solid transparent;
}

:root[data-theme='dark'] {
  --t-social-links-border: 1px solid var(--color-lightgrey);
}

.social-links {
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    display: flex;
    margin-right: 10px;
    margin-top: -3px;
    padding: 4px 5px;
    border-radius: 5px;
    color: white;
    @include boxShadowMd();

    &.github {
      background-color: $github;
      border: var(--t-social-links-border);
    }

    &.linkedin {
      background-color: $linkedin;
      border: var(--t-social-links-border);
    }

    svg {
      min-width: 27px !important;
      min-height: 27px !important;
    }
  }
}