:root {
  // Background
  --primary-bg-extra-dark: #192739;
  --primary-bg-dark: #273b56;
  --primary-bg-medium-dark: #384f6e;
  --primary-bg: #5d718d;
  --primary-bg-medium: #7b93b6;
  --primary-bg-light: #cdd5df;
  --primary-bg-hover: #326290;
  --bg-lightgrey-medium-dark: rgb(211, 211, 211);
  --bg-lightgrey-medium: rgb(218, 218, 218);
  --bg-lightgrey: rgb(255, 255, 255);
  --bg-lightgrey-light: rgb(255, 255, 255);
  // Secondary Background
  --secondary-bg: rgb(255, 199, 62);
  --secondary-bg-opacity-9: rgba(255, 200, 62, 0.9);
  --secondary-bg-opacity-8: rgba(255, 200, 62, 0.8);
  --secondary-bg-opacity-7: rgba(255, 200, 62, 0.7);
  --secondary-bg-opacity-6: rgba(255, 200, 62, 0.6);
  --secondary-bg-opacity-5: rgba(255, 200, 62, 0.5);
  // Color
  --primary-color-extra-dark: #192739;
  --primary-color-dark: #273b56;
  --primary-color-medium-dark: #384f6e;
  --primary-color: #5d718d;
  --primary-color-medium: #7b93b6;
  --primary-color-light: #cdd5df;
  --primary-color-hover: #326290;
  --color-lightgrey-medium-dark: rgb(244, 244, 244);
  --color-lightgrey-medium: rgb(218, 218, 218);
  --color-lightgrey: rgb(224, 224, 224);
  --color-lightgrey-light: rgb(235, 235, 235);
}

:root[data-theme="light"] {
  // General
  --t-primary-color: var(--primary-color);
  --t-primary-color-dark: var(--primary-color-dark);
  --t-primary-color-extra-dark: var(--primary-color-extra-dark);
  --t-primary-color-light: var(--primary-color-medium);
  --t-white-lightgrey-bg: #fff;
  --t-white-lightgrey-color: #fff;
  // Specific
  --t-modal-bg: #fff;
  --t-header-footer-bg: var(--primary-bg);
}
:root[data-theme="dark"] {
  // General
  --t-primary-color: var(--color-lightgrey-medium-dark);
  --t-primary-color-dark: var(--color-lightgrey);
  --t-primary-color-extra-dark: #fff;
  --t-primary-color-light: var(--color-lightgrey-light);
  --t-white-lightgrey-bg: var(--bg-lightgrey-medium);
  --t-white-lightgrey-color: var(--color-lightgrey-medium-dark);
  // Specific
  --t-modal-bg: var(--primary-bg-medium-dark);
  --t-header-footer-bg: var(--primary-bg-dark);
}
:root.transition,
:root.transition *,
:root.transition *:before,
:root.transition *:after {
  transition: cubic-bezier(0.68, -0.55, 0.27, 01.55) 420ms !important;
  transition-delay: 0 !important;
}
body {
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.05em;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
