@mixin fadeIn() {
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  animation: fadeIn 800ms;
}
@mixin fadeInfinite($from, $to, $duration) {
  @keyframes fade {
    from {
      opacity: $from;
    }
    to {
      opacity: $to;
    }
  }
  animation: fade $duration infinite alternate-reverse;
}

@mixin blur() {
  @keyframes blur {
    from {
      filter: blur(3px);
      opacity: 0;
    }
    to {
      filter: blur(0);
      opacity: 1;
    }
  }
  animation: blur 0.4s;
}

@mixin slideInLeftInfinite5() {
  @keyframes slideinLeft {
    from {
      margin-left: 5%;
    }
    to {
      margin-left: -5%;
    }
  }
  animation: slideinLeft 3s infinite alternate;
}

@mixin slideinRight() {
  @keyframes slideinRight {
    from {
      margin-right: 100%;
      width: 300%;
    }
    to {
      margin-right: 0%;
      width: 100%;
    }
  }
  animation: slideinRight 1s;
}

@mixin slideUpDownInfinite($from, $to) {
  @keyframes slideUpDownInfinite {
    from {
      margin-bottom: $from;
    }
    to {
      margin-top: $to;
    }
  }
  animation: slideUpDownInfinite 1s infinite alternate;
}

@mixin rotateInfiniteAlternate30() {
  @keyframes rotating {
    from {
      -ms-transform: rotate(-30deg);
      -moz-transform: rotate(-30deg);
      -webkit-transform: rotate(-30deg);
      -o-transform: rotate(-30deg);
      transform: rotate(-30deg);
    }
    to {
      -ms-transform: rotate(30deg);
      -moz-transform: rotate(30deg);
      -webkit-transform: rotate(30deg);
      -o-transform: rotate(30deg);
      transform: rotate(30deg);
    }
  }
  animation: 3s rotating infinite alternate-reverse;
}

@mixin rotateInfiniteAlternate($from, $to, $duration) {
  @keyframes rotating {
    from {
      -ms-transform: rotate($from);
      -moz-transform: rotate($from);
      -webkit-transform: rotate($from);
      -o-transform: rotate($from);
      transform: rotate($from);
    }
    to {
      -ms-transform: rotate($to);
      -moz-transform: rotate($to);
      -webkit-transform: rotate($to);
      -o-transform: rotate($to);
      transform: rotate($to);
    }  
  }
  animation: $duration rotating infinite alternate-reverse;
}

@mixin bounceInfiniteAlternate($to, $duration) {  
  @keyframes bounce {
    0%   { transform: scale(1,1)    translateY(0); }
    10%  { transform: scale(1.1,.9) translateY(0); }
    30%  { transform: scale(.9,1.1) translateY($to); }
    50%  { transform: scale(1,1)    translateY(0); }
    100% { transform: scale(1,1)    translateY(0); }
}
  animation: $duration bounce infinite alternate-reverse;
}
