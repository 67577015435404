@import "scss/variables";
@import "scss/mixins";

:root[data-theme="light"] {
  --t-menu-sider-web-bg: var(--bg-lightgrey);
  --t-menu-sider-web-item-color: var(--primary-color-dark);
  --t-menu-sider-web-item-hover-bg: var(--primary-bg-dark);
  --t-menu-sider-web-item-hover-color: #fff;
  --t-menu-sder-web-item-selected-bg: var(--primary-bg-dark);
  --t-menu-sider-web-item-selected-color: var(--primary-color-light);
}

:root[data-theme="dark"] {
  --t-menu-sider-web-bg: var(--primary-bg-dark);
  --t-menu-sider-web-item-color: #fff;
  --t-menu-sider-web-item-hover-bg: var(--primary-bg-light);
  --t-menu-sider-web-item-hover-color: var(--primary-color-dark);
  --t-menu-sder-web-item-selected-bg: var(--primary-bg-light);
  --t-menu-sider-web-item-selected-color: var(--primary-color-dark);
}
.ant-layout-sider {
  @include boxShadowLg();
}
.menu-sider-web {
  position: fixed;
  right: -1px;
  top: 56px;
  min-height: calc(100vh - 56px);
  overflow: hidden;
  background-color: var(--t-menu-sider-web-bg);
  border-bottom-right-radius: $border-radius;
  margin-top: 0 !important;
  @media (min-width: $media-breakpoint-up-lg) {
    display: none !important;
  }
  @media (min-width: $media-breakpoint-up-md) {
    top: 61px;
    min-height: calc(100vh - 61px);
  }
  .ant-menu-item-selected {
    background-color: var(--t-menu-sder-web-item-selected-bg) !important;
    a {
      color: var(--t-menu-sider-web-item-selected-color) !important;
    }
  }
  &__item {
    padding-left: 10px !important;
    margin: 0 !important;
    height: 30px !important;
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: $font-size-sm;
    width: 100% !important;
    background-color: var(--t-menu-sider-web-bg);
    @media (min-width: $media-breakpoint-up-sm) {
      font-size: $font-size-md;
    }
    &:hover,
    &:focus {
      background-color: var(--t-menu-sider-web-item-hover-bg) !important;
    }
    a {
      color: var(--t-menu-sider-web-item-color) !important;
      &:hover,
      &:focus {
        color: var(--t-menu-sider-web-item-hover-color) !important;
      }
    }
    &-social-sider {
      position: absolute;
      bottom: 0;
      margin-left: 58px !important;
      margin: 1.4rem;
    }
  }
}
