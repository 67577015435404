:root[data-theme="light"] {
  --button-primary-bg: var(--primary-color);
  --button-primary-light-border: 1px solid var(--primary-color);
}
:root[data-theme="dark"] {
  --button-primary-bg: var(--primary-color-extra-dark);
  --button-primary-light-border: 1px solid var(--color-lightgrey);
}

@mixin buttonPrimary() {
  color: #fff;
  background-color: var(--button-primary-bg);
  border: 1px solid #556985;
  font-weight: bold;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  border-radius: 5px;
  @include boxShadowMd();
  &:focus,
  &:active {
    color: #fff;
    background-color: var(--button-primary-bg);
    border: 1px solid #556985;
  }
  &:hover {
    color: #273b56d3;
    background-color: #cdd5df;
    border: 1px solid #5d718d;
    @include boxShadowLg();
  }
  @media (min-width: $media-breakpoint-up-xs) {
    font-size: 14px;
  }
  @media (min-width: $media-breakpoint-up-sm) {
    font-size: 15px;
  }
}

@mixin buttonPrimaryLight() {
  color: #273b56d3;
  background-color: #cdd5df;
  border: 1px solid #273b56d3;
  font-weight: bold;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  border-radius: 5px;
  @include boxShadowMd();
  &:active,
  &:focus {
    color: #273b56d3;
    background-color: #cdd5df;
    border: var(--button-primary-light-border);
  }
  &:hover {
    color: #fff;
    background-color: #273b56;
    border: var(--button-primary-light-border);
    @include boxShadowLg();
  }
  @media (min-width: $media-breakpoint-up-xs) {
    font-size: 14px;
  }
  @media (min-width: $media-breakpoint-up-sm) {
    font-size: 15px;
  }
}

@mixin titleH1() {
  color: $font-primary-dark;
  font-weight: bold;
  font-size: 1.8em;
  margin-top: 20px;
  @media (min-width: $media-breakpoint-up-sm) {
    font-size: 1.9em;
  }
  @media (min-width: $media-breakpoint-up-md) {
    font-size: 2.1em;
  }
}

@mixin titleH2() {
  color: $font-primary-dark;
  font-weight: bold;
  font-size: 1.5em;
  @media (min-width: $media-breakpoint-up-sm) {
    font-size: 1.6em;
  }
  @media (min-width: $media-breakpoint-up-md) {
    font-size: 1.7em;
  }
}
@mixin H3() {
  color: $font-primary-dark;
  font-weight: bold;
  font-size: 1.1em;
  @media (min-width: $media-breakpoint-up-sm) {
    font-size: 1.2em;
  }
  @media (min-width: $media-breakpoint-up-md) {
    font-size: 1.3em;
  }
}

@mixin H4() {
  color: $font-primary-dark;
  font-weight: bold;
  font-size: 1em;
  @media (min-width: $media-breakpoint-up-sm) {
    font-size: 1.1em;
  }
  @media (min-width: $media-breakpoint-up-md) {
    font-size: 1.2em;
  }
}

@mixin H5() {
  color: $font-primary-dark;
  font-weight: bold;
  font-size: 1em;
  @media (min-width: $media-breakpoint-up-sm) {
    font-size: 1.1em;
  }
}

@mixin p() {
  color: $primary-color;
  font-size: 1em;
  font-weight: 500;
  @media (min-width: $media-breakpoint-up-sm) {
    font-size: 1.1em;
  }
  @media (min-width: $media-breakpoint-up-sm) {
    font-size: 1.2em;
  }
}

@mixin boxShadowSm() {
  -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.15) !important;
  -moz-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.15) !important;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.15) !important;
}

@mixin boxShadowMd() {
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15) !important;
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15) !important;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15) !important;
}

@mixin boxShadowLg() {
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3) !important;
  -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3) !important;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3) !important;
}

@mixin boxShadowXl() {
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5) !important;
  -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5) !important;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5) !important;
}
