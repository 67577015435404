@import "./scss/variables";
@import "./scss/mixins";
@import "./scss/animations";

:root[data-theme="light"] {
  --t-ant-message-notice-bg: var(--primary-bg-dark);
  --t-ant-message-notice-color: #fff;
  --t-offline-tag-alert-bg: var(--primary-bg-dark);
  --t-offline-tag-alert-color: #fff;
}
:root[data-theme="dark"] {
  --t-ant-message-notice-bg: var(--primary-bg-light);
  --t-ant-message-notice-color: var(--primary-color-dark);
  --t-offline-tag-alert-bg: #cdd5df;
  --t-offline-tag-alert-color: var(--primary-bg-dark);
}
.ant-message-notice {
  &:first-child {
    margin-top: 40px;
    @media (min-width: $media-breakpoint-up-md) {
      margin-top: 45px;
    }
    @media (min-width: $media-breakpoint-up-lg) {
      margin-top: 50px;
    }
    @media (min-width: $media-breakpoint-up-xl) {
      margin-top: 55px;
    }
    .ant-message-notice-content {
      border-top: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
  &-content {
    background-color: var(--t-ant-message-notice-bg) !important;
    border: 2px solid $primary-color-extra-dark;
    color: var(--t-ant-message-notice-color);
    font-weight: 500;
    border-radius: 5px;
    @include boxShadowXl();
  }
}
.offline-message {
  position: fixed;
  right: calc(50% - 50px);
  top: 18px;
  z-index: 99999;
  color: var(--t-offline-tag-alert-color);
  background-color: var(--t-offline-tag-alert-bg);
  font-weight: bold;
  border: 2px solid $primary-color-extra-dark;
  border-radius: 10px;
  @include boxShadowLg();
  @include fadeInfinite(0.9, 1, 1s);
  @media (min-width: $media-breakpoint-up-md) {
    top: 21px;
  }
  @media (min-width: $media-breakpoint-up-lg) {
    right: 160px;
    top: 24px;
  }
  @media (min-width: $media-breakpoint-up-xl) {
    top: 27px;
  }
  .anticon-stop {
    color: red;
  }
}
.offline-message-alert {
  position: fixed;
  top: 55px;
  width: 100%;
  padding-right: 85px !important;
  font-size: 0.9em;
  z-index: 100;
  border: 0;
  border-top: 2px solid $primary-color;
  background-color: var(--t-offline-tag-alert-bg);
  @include boxShadowLg();
  @media (min-width: $media-breakpoint-up-md) {
    top: 60px;
  }
  @media (min-width: $media-breakpoint-up-lg) {
    top: 65px;
  }
  @media (min-width: $media-breakpoint-up-xl) {
    top: 70px;
  }
  .ant-alert-message {
    color: var(--t-offline-tag-alert-color) !important;
    font-weight: 500;
  }
  .ant-alert-close-icon {
    span {
      color: var(--t-offline-tag-alert-color);
    }
  }
}
.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.add-fade {
  @include fadeIn();
}
